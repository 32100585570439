/* Add margin around editor area */
/* #gjs .gjs-cv-canvas {
	margin: 10px;
	width: calc(100% - var(--gjs-left-width) - 20px);
} */

/* Theming */

/* Primary color for the background */
.gjs-one-bg {
	background-color: #42424a !important;
}

/* Secondary color for the text color */
.gjs-two-color {
	color: #f0f2f5 !important;
}

/* Tertiary color for the background */
.gjs-three-bg {
	background-color: #de0f0f !important;
	color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
	color: #49a3f1 !important;
}

.html-editor-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	background: white;
	z-index: 1000;
}

.html-editor-container.open {
	display: block;
}

.html-editor-container.closed {
	display: none;
}

.context-menu {
	display: none;
	position: absolute;
	z-index: 10000;
	width: 150px;
	background-color: white;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	overflow: hidden;
}

.gjs-rte-action,
.gjs-toolbar-item {
	min-width: 25px !important;
	width: auto !important;
}

.html-editor-medium-text {
	font-size: 1rem !important;
	line-height: 1rem !important;
}

/* Hide "classe" sections on style component tab */
.gjs-clm-tags {
	display: none;
}
