/* We can remove the border we've set at the beginning */
#gjs {
	border: none;
}
/* Theming */

/* Primary color for the background */
.gjs-one-bg {
	background-color: #42424a !important;
}

/* Secondary color for the text color */
.gjs-two-color {
	color: #f0f2f5 !important;
}

/* Tertiary color for the background */
.gjs-three-bg {
	background-color: #de0f0f !important;
	color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
	color: #49a3f1 !important;
}
