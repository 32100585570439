/* Dropdown menu */

.elevation-1 {
	box-shadow:
		0px 1px 3px rgba(0, 0, 0, 0.2),
		0px 1px 2px rgba(0, 0, 0, 0.12);
}

.elevation-2 {
	box-shadow:
		0px 3px 6px rgba(0, 0, 0, 0.16),
		0px 3px 6px rgba(0, 0, 0, 0.23);
}

.elevation-3 {
	box-shadow:
		0px 10px 20px rgba(0, 0, 0, 0.19),
		0px 6px 6px rgba(0, 0, 0, 0.23);
}

.dropdown-menu {
	background: hsl(215, 15%, 97%);
	border: 1px solid var(--gray-1);
	box-shadow:
		0px 10px 20px rgba(0, 0, 0, 0.19),
		0px 6px 6px rgba(0, 0, 0, 0.23);
	/* box-shadow: var(--shadow); */
	overflow: visible;
	border-radius: 2px;
	position: relative;
	width: max-content;
	border-radius: 4px;
	--Paper-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
		0px 3px 14px 2px rgba(0, 0, 0, 0.12);

	/* Sous-menu parent */
	.menu-item {
		position: relative;

		&:not(:last-child) {
			border-bottom: 1px solid var(--gray-3);
		}

		button {
			background-color: transparent;
			width: 100%;
			padding: 0.5rem 1rem;
			cursor: pointer;
			color: var(--dark);
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			column-gap: 1rem;

			padding: 15px 32px;
			background-color: #ffff;
			border: 1px solid rgba(204, 204, 204, 0.333);

			&:hover,
			&:hover.is-selected {
				background-color: var(--gray-3);
			}

			&.is-selected {
				background-color: var(--gray-2);
			}
		}

		/* Gestion des sous-menus */
		.submenu {
			position: absolute;
			--Paper-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
				0px 3px 14px 2px rgba(0, 0, 0, 0.12);
			top: 0;
			left: 95%;
			margin-left: 0.2rem;
			background-color: hsl(215, 15%, 97%);
			border: 1px solid var(--gray-1);
			box-shadow: var(--shadow);
			width: max-content;
			z-index: 10;
			overflow: visible;
			border-radius: 4px;
			box-shadow:
				0px 10px 20px rgba(0, 0, 0, 0.19),
				0px 6px 6px rgba(0, 0, 0, 0.23);

			/* Sous-menu des éléments */
			.menu-item {
				position: relative;

				button {
					&:hover {
						background-color: var(--gray-3);
					}
				}
			}
		}
	}
}
