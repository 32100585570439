@font-face {
	font-family: "Cedora-Bold";
	src: local("Cedora-Bold"), url("../fonts/Cedora-Bold.otf") format("opentype");
	font-weight: bold;
}

@font-face {
	font-family: "Cedora-Regular";
	src: local("Cedora-Regular"), url("../fonts/Cedora-Regular.otf") format("opentype");
	font-weight: bold;
}

.sidenavTitle {
	transform: translateY(0%) !important;
	opacity: 1 !important;
	transition: 0.2s all ease-in-out;
}

.reduceSidenavTitle {
	transform: translateY(40%) !important;
	opacity: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
}

.brandImageContainer {
	padding: 0 !important;
	margin: 10px !important;
	margin-top: 20px !important;
	display: flex;
	justify-content: center;
}

.brandImage {
	transition: 0.2s all ease-in-out !important;
	max-height: 5vh !important;
	width: auto !important;
}

.brandImageMiniSize {
}

/*
    COLLAPSE TABLE
*/

/* Row type: "total" */
.row_total_light > td > div {
	font-weight: 900;
	color: black !important;
}

.row_total_dark > td > div {
	font-weight: 900;
	color: white !important;
}

/* Row type: "divider" */
.row_divider_light {
	background-color: #616166;
	height: 3vh;
}

.row_divider_dark {
	background-color: #4c5364;
	height: 3vh;
}

/* Row type: "title" */
.row_title_light > td > div {
	font-weight: 900;
	color: white !important;
}

.row_title_light {
	background-color: #616166;
	border-radius: 5px;
}

.row_title_light > td {
	background-color: #616166 !important;
}

.row_title_dark > td > div {
	font-weight: 900;
	color: white !important;
}

.row_title_dark {
	background-color: #4c5364;
	border-radius: 5px;
}

.row_title_dark > td {
	background-color: #4c5364 !important;
}

/* Row type: "collapse" */
.row_collapse_light {
	color: black;
	font-weight: 500;
}

.row_collapse_dark {
	color: white;
	font-weight: 500;
}

.row_collapsed_border_light {
	border-left: 5px solid #344767 !important;
}

.row_collapsed_border_dark {
	border-left: 5px solid white !important;
}

/* Row type: "collapse" icon */

.row_collapse_icon_light {
	font-weight: 900;
	color: black;
	display: flex;
	align-items: center;
}
.row_collapse_icon_dark {
	font-weight: 900;
	color: white;
	display: flex;
	align-items: center;
}

/* Date input */

.date_input_light,
.date_input_dark {
	border: 1px solid #344767 !important;
	border-radius: 5px !important;
}

.date_input_light fieldset,
.date_input_dark fieldset {
	border: none !important;
}

.date_input_light input {
	color: #344767 !important;
}

.date_input_light input::placeholder {
	color: #344767 !important;
}

.date_input_dark {
	background-color: rgba(255, 255, 255, 0.116) !important;
}

th * {
	text-transform: none !important;
}
