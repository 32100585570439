.dropzone {
	border: 2px dashed #007bff;
	border-radius: 5px;
	padding: 50px;
	text-align: center;
	transition: border 0.24s ease-in-out;
}

.dropzone:hover {
	border-color: #0056b3;
}

.error {
	color: red;
	margin-top: 10px;
}
