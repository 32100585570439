.stickyFirstCell_light {
	position: absolute;
	left: 0;
	top: auto;
	margin: 0;
	white-space: nowrap;
	background-color: white;
	border-right: 0.0625rem solid #f0f2f5;
}

.stickyFirstCell_dark {
	position: absolute;
	left: 0;
	top: auto;
	margin: 0;
	white-space: nowrap;
	background-color: #202940;
	border-right: 0.0625rem solid #f0f2f566;
}

.cell_zips div {
	width: 100%;
	max-height: 25vh;
	overflow-y: scroll;
}

.cell_zips div table {
	max-height: 25vh;
	overflow-y: scroll;
}

.defaultDataTableClickableRow:hover {
	cursor: pointer;
	background-color: #f0f2f5;
}

.defaultDataTableSelectedRow {
	background-color: #2196f3 !important;
	border-radius: 10px;
}

.defaultDataTableSelectedRow td div {
	color: white !important;
	font-weight: bold !important;
}
