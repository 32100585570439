.answer-item-card {
	position: relative;
	width: 100%;
	height: 200px;
	overflow: hidden;
	background-color: #f9f9f9;
	cursor: pointer;
	box-sizing: border-box;
	user-select: none;
}

.answer-item-card:hover .answer-item-card-overlay {
	background-color: #49a3f1 !important;
}

.answer-item-card:hover .answer-item-card-overlay .answer-item-card-text * {
	color: white !important;
}

.answer-item-card-selected .answer-item-card-overlay {
	background-color: #49a3f1 !important;
}

.answer-item-card-selected .answer-item-card-overlay .answer-item-card-text * {
	color: white !important;
}

.answer-item-card-preview {
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(0.2); /* réduit l'échelle à 20% */
	transform-origin: top left;
	width: 500%;
	height: 500%;
	pointer-events: none; /* pour que la preview ne capte pas les événements de la souris */
}

.answer-item-card-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.093), transparent) !important;
	color: white;
	padding: 10px;
	box-sizing: border-box;
}

.answer-item-card-text h2 {
	margin: 0;
	font-size: 1.2em;
}

.answer-item-card-text p {
	margin: 5px 0 0;
	font-size: 0.9em;
}

.answer-item-card-actions {
	position: absolute;
	top: 5px;
	right: 5px;
}
