.fAIbrik-variable {
	padding: 4px 6px;
	border-radius: 6px;
	font-size: 0.75rem;
	word-break: keep-all;
	white-space: nowrap;
	user-select: none;
	/* margin: 0px 4px; */
}

.advancedInputStyle {
	width: 100%;
	min-height: "50px";
	border: 1px solid #d2d6da;
	padding: 0.75rem;
	overflow: auto;
	word-break: break-all;
	color: #495057;
	font-size: 0.875rem;
	font-weight: 400;
	font-family: Roboto, Helvetica, Arial, sans-serif;
}
